import { ReactNode } from 'react';
import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import logo from '../../casa-flutuante-logo.png'


const Links = ['Sobre o Projeto', 'Livro', 'Galeria', 'Podcast', 'Links'];

const stringToSlug = (value: string) => {
    value = value.replace(/^\s+|\s+$/g, '');
    value = value.toLowerCase();

    const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length; i < l; i++) {
        value = value.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    value = value.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');

    return value;
}

const NavLink = ({ children }: { children: string }) => (
  <Link
    px={2}
    py={1}
    _hover={{
      textDecoration: 'none',
      color: 'gray.700',
    }}
    style={{ color: '#3A3A3A', fontWeight: 500 }}
    href={stringToSlug(children)}>
    {children}
  </Link>
);

export default function Header({
  children
}: {
  children: ReactNode;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box p={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack p={6} spacing={8} alignItems={'center'}>
            <Box>
              <Link href='/'>
                <img src={logo} style={{ width: "190px", height: "auto" }} alt="Casa Flutuante" />
              </Link>
            </Box>
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </HStack>
          </HStack>
          <Flex alignItems={'center'}>
            <Link
              href={'contato'}
              pr={8}
              _hover={{
                textDecoration: 'none',
                color: '#000',
              }}
              style={{ color: '#3A3A3A', fontWeight: 500 }}
            >Contato</Link>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Box p={4}>{ children }</Box>
    </>
  );
}
