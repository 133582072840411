import {
  Box,
  Text
} from "@chakra-ui/react";
import PageTitle from "../../components/PageTitle";
import React from "react";


const BookPage = () => {
  return (
    <Box p={6}>
      <PageTitle>Livro</PageTitle>
      <Text align='center' m={12}>O livro ilustrado “Cidade Flutuante” está em fase de produção com previsão de lançamento para Janeiro de 2022 e também estará disponível em uma versão digital no site.</Text>
    </Box>
  )
}

export default BookPage;
