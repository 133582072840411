import styles from './index.module.css';

interface ImageModalProps {
  image: string;
  maxWidth: number;
  isOpen: boolean;
  onClose: any;
}

const ImageModal = (props: ImageModalProps) => {
  const { image, maxWidth, isOpen, onClose } = props;
  const height = Math.floor(window.innerHeight * 0.8);

  return (
    <div className={isOpen ? `${styles.modal} ${styles.flex}` : styles.modal}>
      <img src={image} style={{ 'maxWidth': maxWidth, 'height': height }} className={styles.modalContent} alt={''} />
      <span className={styles.closeBtn} onClick={onClose}>&times;</span>
    </div>
  )
}

export default ImageModal;
