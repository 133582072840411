import {
  Box,
  HStack,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text
} from "@chakra-ui/react";
import { Icon } from '@chakra-ui/react';
import { AiFillMail, AiFillInstagram, AiOutlineLink } from "react-icons/ai";
import PageTitle from "../../components/PageTitle";
import cidadeFlutuante from "../../casa-flutuante.png"


const ContactPage = () => {
  return (
    <Box p={6}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Box>
          <PageTitle>Contato</PageTitle>
          <Stack spacing={4}>
            <HStack>
              <Icon as={AiFillMail} w={8} h={8} />
              <Text>cidadeflutuantedemanaus@gmail.com</Text>
            </HStack>
            <HStack>
              <Icon as={AiFillInstagram} w={8} h={8} />
              <Link isExternal>@cidadeflutuante</Link>
            </HStack>
            <HStack>
              <Icon as={AiOutlineLink} w={8} h={8} />
              <Link isExternal>https://linktr.ee/cidadeflutuante</Link>
            </HStack>
          </Stack>
        </Box>
        <Box>
          <Image src={cidadeFlutuante} alt='Cidade Flutuante' />
        </Box>
      </SimpleGrid>
    </Box>
  )
}

export default ContactPage;
