import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import HomePage from "../pages/HomePage";
import BookPage from "../pages/BookPage";
import GalleryPage from "../pages/GalleryPage";
import LinksPage from "../pages/LinksPage";
import PodcastsPage from "../pages/PodcastsPage";
import ContactPage from "../pages/ContactPage";
import AboutPage from "../pages/AboutPage";

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/sobre-o-projeto" component={AboutPage} />
      <Route exact path="/livro" component={BookPage} />
      <Route exact path="/galeria" component={GalleryPage} />
      <Route exact path="/podcast" component={PodcastsPage} />
      <Route exact path="/links" component={LinksPage} />
      <Route exact path="/contato" component={ContactPage} />
    </BrowserRouter>
  );
};

export default Routes;
