import React, { useState } from "react";
import {
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import PageTitle from "../../components/PageTitle";
import ImageWrapper from '../../components/ImageWrapper';
import ImageModal from "../../components/ImageModal";
import beiradao from '../../beiradão_urbano-jamille_anahata.png';
import cortador from '../../o_cortador_de_juta-sakiko.png';
import flutuantina from '../../flutuantina-sakiko.png';
import cidade from '../../cidade_flutuante-paulo_gersino.jpg';
import maeFlutuantina from '../../mãe_flutuantina-sakiko.png';
import muro from '../../muro_flutuante-sarah_campelo_monteiro.jpeg';
import vida from '../../a_vida_nasce_onde_da_ze-marco_antonio_miranda.jpg'


const GalleryPage = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sellectedImg, setSellectedImg] = useState<string>('');
  const [imageWidth, setImageWidth] = useState<number>(0);

  const images = [
    {'path': beiradao, title: 'Beiradão Urbano', author: 'Jamille Anahata', type: 'Mídia Digital', year: '2021', size: 'S/D', width: 3801, height: 1965 },
    {'path': maeFlutuantina, title: 'Mãe Flutuantina', author: 'Sakiko', type: 'Lápis sobre papel 120g/m²', year: '2021', size: '21 x 29,7cm', width: 6325, height: 5063 },
    {'path': muro, title: 'Muro Flutuante', author: 'Sarah Campelo', type: 'Acrílica sobre papel 180g/m²', year: '2021', size: '21 x 29,7 cm', width: 2777, height: 1971 },
    {'path': cortador, title: 'O Cortador de Juta', author: 'Sakiko', type: 'Pastel seco sobre papel 300g/m²', year: '2021', size: 'S/D', width: 5101, height: 7017 },
    {'path': flutuantina, title: 'Flutuantina', author: 'Sakiko', type: 'Lápis sobre papel 120g/m²', year: '2021', size: '21 x 29,7cm', width: 4703, height: 6174 },
    {'path': cidade, title: 'Cidade Flutuante', author: 'Paulo Gersino', type: 'Aquarela sobre papel Canson 300g/m²', year: '2021', size: '29,7 cm x 42,0 cm', width: 3024, height: 4032 },
    {'path': vida, title: 'A Vida nasce onde dá, Zé', author: 'Marco Antônio Miranda', type: 'Colagem Digital', year: '2021', size: 'S/D', width: 1080, height: 1301 },
  ]

  const openImage = (target: any) => {
    const maxImgHeight = Math.floor(window.innerHeight * 0.8);
    const maxImgWidth = Math.floor(target.width * maxImgHeight / target.height);
    setImageWidth(maxImgWidth);
    setSellectedImg(target.path);
    setIsOpen(true);
  }

  return (
    <Box p={6}>
      <PageTitle>Galeria</PageTitle>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        { images.map((item) => {
          return (
            <div key={item.title} onClick={() => openImage(item)}>
              <ImageWrapper
                path={item.path}
                title={item.title}
                author={item.author}
              />
            </div>
          )
        })}
      </SimpleGrid>
      <ImageModal
        image={sellectedImg}
        maxWidth={imageWidth}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </Box>
  )
}

export default GalleryPage;
