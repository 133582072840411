import {
  Box,
  Image
} from "@chakra-ui/react";

interface image {
  path: string;
  title: string;
  author: string;
}


const ImageWrapper = (props: image) => {
  const { path, title, author } = props;

  return (
    <Box>
      <Image
        src={path}
        className="gallery-img"
        cursor='pointer'
        alt={`${title} - ${author}`} />
    </Box>
  )
}

export default ImageWrapper;
