import React from "react";
import {
  Box, Heading, LinkBox,
  LinkOverlay,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import PageTitle from "../../components/PageTitle";


const LinksPage = () => {
  const links = [
    {
      type: 'Livro',
      title: 'Aspectos Econômicos e Sociais da Cidade Flutuante',
      author: 'Celso Luiz Rocha Serra e Wilson Rodrigues da Cruz',
      link: 'https://drive.google.com/file/d/1sgzKbMy-2feZPQyERmbGeKCu7LiWrBqj/view?usp=sharing'
    },
    {
      type: 'Tese',
      title: 'Cidade flutuante: uma Manaus sobre as águas (1920-1967)',
      author: 'Leno José Barata Souza',
      link: 'https://sapientia.pucsp.br/handle/handle/13222'
    },
    {
      type: 'Artigo',
      title: 'A “Cidade Flutuante” de Manaus: rediscutindo conceitos',
      author: 'Leno José Barata Souza',
      link: 'https://seer.ufrgs.br/aedos/article/view/12507'
    },
    {
      type: 'Reportagem',
      title: 'A "exótica" cidade flutuante de Manaus',
      author: 'Instituto Durango Duarte',
      link: 'https://idd.org.br/reportagens/exotica-cidade-flutuante-de-manaus2/'
    },
    {
      type: 'Curta metragem',
      title: 'Igual a mim... igual a ti (1965)',
      author: 'Roberto Kahane',
      link: 'https://youtu.be/N-SnBMP-yO0?t=720'
    },
    {
      type: 'Instagram',
      title: 'Cidade Flutuante',
      author: '',
      link: 'https://www.instagram.com/cidadeflutuante/'
    },
    {
      type: 'Linktree',
      title: 'Cidade Flutuante',
      author: '',
      link: 'https://linktr.ee/cidadeflutuante'
    },
  ]
  return (
    <Box p={6}>
      <PageTitle>Links</PageTitle>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
        { links.map(item => {
          return (
            <LinkBox as='article' maxW='sm' p='5' borderWidth='1px' rounded='md'>
              <Box>{ item.type }</Box>
              <Heading size='md' my='2'>
                <LinkOverlay href={ item.link } isExternal>
                  { item.title }
                </LinkOverlay>
              </Heading>
              { !!item.author && <Text>{ item.author }</Text> }
            </LinkBox>
          )
        })}
      </SimpleGrid>
    </Box>
  )
}

export default LinksPage;
