import React from "react";
import {
  Box,
  Text
} from "@chakra-ui/react";
import PageTitle from "../../components/PageTitle";


const PodcastsPage = () => {
  return (
    <Box p={6}>
      <PageTitle>Podcast</PageTitle>
      <Text align='center' m={12}>O Podcast será lançado em episódios a partir de Fevereiro de 2022. </Text>
    </Box>
  )
}

export default PodcastsPage;
