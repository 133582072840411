import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom"
import { ChakraProvider } from "@chakra-ui/react"
import App from "./App";
import Header from "./components/Header";
import Footer from "./components/Footer";

ReactDOM.render(
  <BrowserRouter>
    <ChakraProvider>
      <Header>
        <App />
      </Header>
      <Footer />
    </ChakraProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
