import {
  Box, Center, Image,
  SimpleGrid
} from "@chakra-ui/react";
import felicianoLana from '../../feliciano_lana.png';
import amazonasCulturaValor from '../../amazonas_cultura_valor.png';
import sec from '../../sec.png';
import governoAmazonas from '../../governo_amazonas.png';
import secEspecialCultura from '../../sec_especial_cultura.png';
import ministerioTurismo from '../../min_turismo.png';
import governoFederal from '../../governo-federal.png';


const Footer = () => {
  return (
    <Box mt={8} pt={12} pl={10} pr={10} pb={12}>
      <SimpleGrid columns={{ base: 7 }} spacing={4}>
        <Box>
          <Center>
            <Image src={felicianoLana} alt='Feliciano Lana' />
          </Center>
        </Box>
        <Box>
          <Center>
            <Image src={amazonasCulturaValor} alt='Amazonas Cultura de Valor' />
          </Center>
        </Box>
        <Box>
          <Center>
            <Image src={sec} alt='Secretaria de Cultura e Economia Criativa' />
          </Center>
        </Box>
        <Box>
          <Center>
            <Image src={governoAmazonas} alt='Governo do Amazonas' />
          </Center>
        </Box>
        <Box>
          <Center>
            <Image src={secEspecialCultura} alt='Secretaria Especial da Cultura' />
          </Center>
        </Box>
        <Box>
          <Center>
            <Image src={ministerioTurismo} alt='Ministério do Turismo' />
          </Center>
        </Box>
        <Box>
          <Center>
            <Image src={governoFederal} alt='Governo Federal' />
          </Center>
        </Box>
      </SimpleGrid>
    </Box>
  )
}

export default Footer;
