import {
  Box,
  Text
} from "@chakra-ui/react";
import PageTitle from "../../components/PageTitle";
import React from "react";


const AboutPage = () => {
  return (
    <Box p={6}>
      <PageTitle>Sobre o Projeto</PageTitle>
      <Text pb={6}>A Cidade Flutuante foi um aglomerado de casas flutuantes que habitaram a margem esquerda do Rio Negro, na area central de Manaus, entre os anos de 1920 e 1967. Pouco antes de seu desmonte, cerca de onze mil pessoas moravam em mais de duas mil casas flutuantes que se estendiam do igarapé do São Raimundo ao igarapé da Cachoeirinha. A maior concentração deles ficava na zona portuária da cidade, local de trabalho de boa parte de seus moradores, entre a praça da igreja Nossa Sra. dos Remédios e o igarapé do Educandos. Nessa época a Cidade Flutuante era um conglomerado urbano com população maior que a maioria dos municípios do Estado.</Text>
      <Text pb={6}>Com seu crescimento desordenado, devido a ciclos migratórios cada vez mais intensos, e condições de higiene e saúde precárias, o governo do Estado utilizou destes argumentos e decidiu pela retirada dos flutuantes do Rio Negro, processo iniciado em 1965 e finalizado em 1º de Janeiro de 1967. A população flutuantina teve então que se acomodar sobre as terras da cidade, dando origem a diversos dos bairros existentes ainda hoje.</Text>
      <Text pb={8}>Ainda em 1967 é criada a Zona Franca de Manaus tendo como principal porto para chegada e envio de mercadorias o porto do centro da cidade, local antes ocupado pela Cidade Flutuante.</Text>
      <Text pb={6} fontWeight={700}>O Projeto</Text>
      <Text pb={6}>A ideia surgiu em conversas entre amigos quando descobrimos que poucos tinham conhecimento da existência da Cidade Flutuante  O que não é de se espantar já que o local onde ficava a maior concentração de flutuantes foi aterrado, dando origem a chamada Manaus Moderna, complexo viário criado com a intenção de ligar o porto de Manaus ao Distrito Industrial.</Text>
      <Text pb={6}>Decidimos então por organizar um livro, uma exposição projetada e um site com material complementar.</Text>
      <Text pb={6}>O Projeto Cidade Flutuante foi contemplado pelo Programa Cultura Criativa - 2020/Lei Aldir Blanc - Prêmio Feliciano Lana do Governo do Estado do Amazonas, com apoio do Governo Federal - Ministério do Turismo - Secretaria Especial da Cultura, Fundo Nacional de Cultura.</Text>
    </Box>
  )
}

export default AboutPage;
